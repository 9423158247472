// ontology
.ontology-form-field {
  .ontology-prefix-icon {
    width: 36px;
    padding: 0 8px;
    display: block;
  }

  mat-label,
  mat-select,
  input {
    margin-left: 12px;
  }
  mat-select {
    width: calc(100% - 12px);
  }

  .ontology-warning-with-prefix,
  .ontology-error-with-prefix {
    position: relative;
    left: 64px;
    margin-right: 64px;
  }
}
