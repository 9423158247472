/*!
 * Copyright © 2021 - 2023 Swiss National Data and Service Center for the Humanities and/or DaSCH Service Platform contributors.
 *  SPDX-License-Identifier: Apache-2.0
 */

@use '@angular/material' as mat;

// define the primary button typography level
$dsp-primary-button-typography-level: mat.define-typography-level(
        $font-size: 16px,
        $line-height: 36px,
        $font-weight: 400,
        $font-family: 'Roboto, "Helvetica Neue", sans-serif',
        $letter-spacing: normal
);

// combine everything in a typography config
$dsp-typography-config: mat.define-typography-config($button: $dsp-primary-button-typography-level);
