// css for parent value components
@use 'config' as *;

.read-mode-view {
  font: 400 15px/24px sans-serif;

  .show-comment {
    color: $dark-text;

    .mat-icon {
      font-size: 18px;
      display: inline;
      vertical-align: text-bottom;
    }
  }

  .rm-value,
  .rm-comment {
    display: block;
    margin: 0px;
  }

  .rm-comment {
    margin-top: 6px;
    font-size: small;
    border-top: 1px solid rgba(33, 33, 33, 0.1);
  }

  .rm-value.text-value {
    white-space: pre-wrap;
  }

  .rm-value.list:has(.hierarchy:only-child) .last {
    font-weight: normal;
  }

  .rm-value.list,
  .rm-value .hierarchy {
    display: inline-flex;

    .last {
      font-weight: bold;
    }
  }
}

.child-value-component,
.value-component-comment {
  display: block !important;

  textarea.mat-mdc-input-element.cdk-textarea-autosize {
    resize: vertical;
  }
}

.parent-component-wrapper .value-component-comment {
  margin-top: -2em;
}

.parent-value-component
  .child-value-component
  .mat-mdc-form-field-wrapper
  .mat-mdc-form-field-infix {
  border-top: 0em solid transparent;
}

// css for child components of a parent value component

.child-input-component {
  display: inline-block;
  vertical-align: bottom;
  width: 49%;
}

.child-input-component.full-width {
  width: 100%;
}

// custom error message

.custom-error-message {
  font-size: 12px;
  margin-top: -1em;
  padding-bottom: 1em;
}

// viewer operations

.grid-container {
  display: grid;
  grid-template-columns: 80% 20%;
  grid-template-rows: auto auto;
}

.value-component,
.crud-buttons {
  vertical-align: top;
}

.value-component {
  grid-row-start: 1;
  grid-row-end: auto;
  grid-column-start: 1;
  grid-column-end: 2;
  position: relative;
}

.value-component.hover {
  background-color: #f5f5f5;
}

.value-component.highlight {
  background-color: #f4f400;
}

.crud-buttons {
  grid-row-start: 1;
  grid-row-end: auto;
  grid-column-start: 2;
  grid-column-end: 3;
  display: inline-flex;
}

.value-action {
  .material-icons,
  .mat-icon {
    font-size: 18px;
    height: 18px;
    width: 18px;
  }
}

.mat-mdc-form-field-error {
  grid-row-start: 1;
  grid-row-end: auto;
  grid-column-start: 3;
  grid-column-end: end;
}

.deletion-dialog .title {
  font-weight: bold;
  font-size: 18px;
  text-align: center;
}

.deletion-dialog .action-buttons {
  float: right;

  .cancel {
    padding: 0 16px;
  }
}

.deletion-dialog-message {
  font-size: 16px;
  background-color: #ededf5;
  border: 1px solid #d8d8df;
  border-radius: 5px;
  padding: 20px 10px 20px 10px;
  text-align: center;
}

.deletion-dialog-message .deletion-comment {
  min-width: 80%;
}

.ck-content code {
  font-family: monospace !important;
}

.ck-content pre {
  background-color: inherit !important;
}

code,
pre {
  font-family: monospace !important;
  background-color: hsla(0, 0%, 78%, 0.3);
  padding: 0.15em;
  border-radius: 2px;
}
