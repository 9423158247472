//
// responsive sizes
$grid-breakpoints: (
  watch: 0,
  phone: 576px,
  tablet: 768px,
  notebook: 992px,
  desktop: 1200px,
);

// desktop devices
@media (min-width: map-get($grid-breakpoints, tablet)) {
  .desktop-only {
    display: contents;
  }
  .fill-remaining-space.desktop-only {
    display: flex;
  }
  .content.large.middle.desktop-only {
    display: block;
  }
  .mobile-only,
  .nav-link-mobile {
    display: none;
  }
}

// mobile device: tablet and phone
@media (max-width: map-get($grid-breakpoints, tablet)) {
  .desktop-only {
    display: none;
  }
  .mobile-only {
    display: contents;
  }
}

// mobile device: bigger than phone
@media (min-width: map-get($grid-breakpoints, phone)) {
  // override dsp-ui search panel style
  .app-fulltext-search.desktop-only {
    display: inline-flex !important;
  }
  .advanced-expert-buttons {
    font-size: smaller !important;
    margin-top: -2px !important;
  }
}

// mobile device: tablet and smaller than a tablet
@media (max-width: map-get($grid-breakpoints, tablet)) and (min-width: map-get($grid-breakpoints, phone)) {
  .content {
    &.small,
    &.medium,
    &.large,
    &.extra-large {
      padding: 0;
    }

    &.small {
      max-width: 320px;
    }

    &.medium,
    &.large,
    &.extra-large {
      max-width: 100%;
    }
  }

  .cdk-overlay-pane {
    margin-bottom: 120px !important;
    overflow-y: auto !important;
    /* max-height: 80% !important; */
  }

  .nav-link {
    display: none;
  }
}

// mobile device: phone
@media (max-width: map-get($grid-breakpoints, phone)) {
  // general app layout
  .app {
    display: flex;
    flex-direction: inherit !important;

    .app-content {
      width: 100%;
    }
  }

  .content {
    &.small,
    &.medium,
    &.large,
    &.extra-large {
      padding: 0 !important;
    }

    &.small {
      max-width: 320px !important;
    }

    &.medium,
    &.large,
    &.extra-large {
      max-width: calc(100% - 36px) !important;
    }
  }

  .form-content {
    width: 100% !important;

    .large-field {
      min-width: 100% !important;
    }

    .medium-field {
      width: 100% !important;
      min-width: 100%;
    }

    .small-field {
      width: 100% !important;
      min-width: 100%;
    }

    .form-panel {
      width: 100% !important;
    }
  }

  .cdk-overlay-pane {
    overflow-y: auto !important;
    max-height: 500px !important;
  }

  .more-space-top {
    margin-top: 24px !important;
  }

  .nav-link {
    display: none;
  }

  .menu-footer {
    .menu-action.logout {
      background: white;
    }
  }
}
